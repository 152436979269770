.wechat-contact-block {
  position: fixed;
  right: 12px;
  bottom: 35px;
  z-index: 9999;
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 2px solid #FFF;
  border-radius: 100%;
  text-align: center;
  font-size: 26px;
  transition: all 300ms;
  background-color: #3a3a3a;

  a {
    color: #FFF;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
  &__icon {
    font-family: FontAwesome !important;
    font-style: normal;
  }

  &--theme- {
    &bestproducts {
      background-color: #434A4E;

      &:hover {
        border-color: #434A4E;
      }
    }
    &denzzo {
      background-color: #E1CDA8;

      &:hover {
        border-color: #E1CDA8;
      }
    }
    &ixia {
      background-color: #3a3a3a;

      &:hover {
        border-color: #deb283;
        background: #deb283;
      }
    }
    &juinsa {
      background-color: #47B260;

      &:hover {
        border-color: #47B260;
      }
    }
    &ldkgarden {
      background-color: #0790c9;

      &:hover {
        border-color: #0790c9;
      }
    }
    &momfuncompany {
      &:hover {
        border-color: #00d6e0;
        background-color: #00d6e0;
      }
    }
    &unimasa {
      background-color: #C83353;

      &:hover {
        border-color: #C83353;
      }
    }
  }
}

.wechat-bubble {
  .module-back-to-top {
    .module__action {
      bottom: 100px;
    }
  }
}

@media (min-width: 991px) {
  #wechat_contact {
    display: none;
  }
  .wechat-bubble {
    .module-back-to-top {
      .module__action {
        bottom: 35px;
      }
    }
  }
}

.wechat-cms {
  width: 100%;
  display: grid;
  margin-top: 30px;

  li, h5{
    padding-bottom: 20px;
  }

  img {
    max-width: 300px;
  }

  .wechat-icon-cms{
    font-size: 12em;margin: auto;
  }

  .wechat-id-theme- {
    &bestproducts {
      margin: 15px auto;
      padding: 10px;
      border: 1px solid #434A4E;
    }
    &denzzo {
      margin: 15px auto;
      padding: 10px;
      border: 1px solid #E1CDA8;
    }
    &ixia {
      margin: 15px auto;
      padding: 10px;
      border: 1px solid #deb283;
    }
    &juinsa {
      margin: 15px auto;
      padding: 10px;
      border: 1px solid #47B260;
    }
    &ldkgarden {
      margin: 15px auto;
      padding: 10px;
      border: 1px solid  #0790c9;
    }
    &momfuncompany {
      margin: 15px auto;
      padding: 10px;
      border: 1px solid #00d6e0;
    }
    &unimasa {
      margin: 15px auto;
      padding: 10px;
      border: 1px solid #C83353;
    }
  }
}